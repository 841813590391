// var baseUrl = "https://backend.gutenappetite.de/api/";
// var baseUrl = "https://guten-backend.tajtrudering.de/api/";
var baseUrl = "https://gutenappetite-backend.crunchyapps.com/api/";

export async function checkLatLng(object) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    },
    method: "POST",
    body: JSON.stringify({
      addresses: JSON.stringify({ postcode: object.postcode }),
      restaurant_slug: object.restaurant_slug,
    }),
  };
  return await fetch(
    baseUrl + "customer/checkLatLngExist",
    requestOptions
  ).then((data) => data.json());
}
