<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/orders">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>

              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-3"
                label="Customer *"
                label-for="input-3"
              >
                <multiselect
                  @select="onSelectCustomer"
                  track-by="id"
                  :custom-label="customLabelForCustomer"
                  label="username"
                  v-model="customerId"
                  :options="customers"
                  placeholder="Select Customer"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }} - ({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Restaurant *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="selected"
                  :options="users"
                  @select="onSelect"
                  placeholder="Select Restaurant"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.name }} - ({{
                        option.current_address
                      }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Voucher"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="VoucherLabel"
                  label="username"
                  v-model="voucher"
                  :options="vouchersList"
                  placeholder="Select Voucher"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.unique_code }} - ({{
                        option.voucher.type
                      }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <br /><br />
            </div>
          </div>
        </div>
        <br />

        <div class="card">
          <template>
            <MenuItems
              :meunItems="meunItems"
              @totalPriceIncrement="totalPriceIncrement"
              @deleteData="deleteData"
              :meunItemsTempArray="meunItemsTempArray"
              @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
            />
          </template>

          <br />
        </div>

        <br />

        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group label="Type:">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="type"
                  @change="onChangeType()"
                >
                  <b-form-radio value="1">Delivery</b-form-radio>
                  <b-form-radio value="0">Pickup</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <div v-if="showInputForDelivery">
                <b-form-group
                  id="input-group-3"
                  label="Delivery Address *"
                  label-for="input-3"
                >
                  <b-form-select
                    @change="onSelectAddress(deliveryAddress)"
                    v-model="deliveryAddress"
                    class="mb-3"
                    required
                  >
                    <option value="null" disabled>
                      Select Delivery Address
                    </option>
                    <option
                      v-bind:value="arm"
                      v-for="arm in this.deliveryAddresses"
                      v-bind:key="arm.postcode"
                    >
                      {{ bindAddressForSelect(arm) }}
                    </option>
                    <option value="new">Add New</option>
                  </b-form-select>
                </b-form-group>
                <br />

                <b-form-group
                  label="Delivery Address *"
                  v-if="this.deliveryAddress == 'new'"
                >
                  <GmapAutocomplete
                    id="autocomplete"
                    ref="autocomplete"
                    class="form-control"
                    placeholder="Please type address"
                    @place_changed="googlePlaceApi"
                  >
                  </GmapAutocomplete>
                </b-form-group>

                <div
                  style="display:flex;justify-content:space-between;align-items:center;"
                >
                  <div style="width:20%;margin-left:10px">
                    <b-form-group label="Post Code *">
                      <b-form-input
                        id="input-2"
                        disabled
                        v-on:input="checkLatLngExist"
                        v-model="postcode"
                        placeholder="Enter Post Code"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div style="width:20%;margin-left:10px">
                    <b-form-group label="Street Name *">
                      <b-form-input
                        id="input-2"
                        v-model="street"
                        placeholder="Enter street name"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div style="width:20%;margin-left:10px">
                    <b-form-group label="City *">
                      <b-form-input
                        disabled
                        id="input-2"
                        v-model="city"
                        placeholder="Enter City"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div style="width:20%;margin-left:10px">
                    <b-form-group label="Floor">
                      <b-form-input
                        id="input-2"
                        v-model="floor"
                        placeholder="Enter Floor"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div style="width:20%;margin-left:10px">
                    <b-form-group label="House No *">
                      <b-form-input
                        id="input-2"
                        v-model="house_no"
                        placeholder="Enter House No"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <b-form-group
                id="input-group-2"
                label="Total Price:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="totalPrice"
                  type="number"
                  required
                  placeholder="Total Price"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Voucher Discount"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="voucher_discount"
                  type="number"
                  required
                  placeholder="Total Price"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Order Status *"
                label-for="input-3"
              >
                <b-form-select
                  @change="onChangeOrderStatus"
                  v-model="orderStatus"
                  class="col-md-12 col-6"
                >
                  <option value="null">
                    Select Order Status
                  </option>
                  <option
                    v-for="orderstatus in this.orderStatuses"
                    :value="orderstatus"
                    :key="orderstatus"
                    >{{ orderstatus }}
                  </option>
                </b-form-select>
              </b-form-group>

              <b-form-group
                v-if="this.orderStatus == 'dispatched'"
                id="input-group-3"
                label="Rider *"
                label-for="input-3"
              >
                <multiselect
                  @select="onSelectRider"
                  track-by="id"
                  :custom-label="customLabelForRider"
                  label="username"
                  v-model="riderId"
                  :options="riders"
                  placeholder="Select Rider"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.f_name ? option.f_name : "" }}
                      {{ option.l_name ? option.l_name : "" }} - ({{
                        option.email
                      }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                v-if="this.orderStatus == 'dispatched'"
                id="input-group-2"
                label="Temperature"
                type="text"
                label-for="input-2"
              >
                <b-form-input
                  v-model="temperature"
                  @keypress="onlyForCurrency"
                  placeholder="Temperature"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="showDeliveryTimeInput"
                id="input-group-3"
                label="Delivery Time:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="deliveryTime"
                  :options="deliveryTimes"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Comment:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="comment"
                  placeholder="Enter Comment here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <br />
              <div class="col-sm-2 col-md-2">
                <b-button class="btn-info" ref="save" type="submit"
                  >Save</b-button
                >
              </div>
              <div style="display:none">
                <qr-code
                  id="qr_code"
                  :text="
                    this.orderResponce.qrcode_string
                      ? this.orderResponce.qrcode_string
                      : ''
                  "
                  :size="100"
                ></qr-code>
                <table id="bootstrap_table">
                  <tr>
                    <th>Item Name</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                  <tr v-for="data in orderResponceArray" :key="data.id">
                    <td>{{ data.name }}</td>
                    <td>{{ data.quantity.toString() }}</td>
                    <td>
                      {{ data.total_price.toString() }}
                    </td>
                  </tr>
                </table>
              </div>

              <br /><br />
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteMeunItem="deleteMeunItem"
            @closeModal="closeModal"
          />
        </template>

        <br /><br />
        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";
import { checkLatLng } from "../../../apis/ApisLists";
import { validTimeForOrder } from "../../../Helper/helpers";
import VerficationModal from "./VerficationModal";
import MenuItems from "@/view/pages/Orders/MenuItems";
import Multiselect from "vue-multiselect";
import VueQRCodeComponent from "vue-qrcode-component";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

import $ from "jquery";
import axios from "axios";
Vue.component("qr-code", VueQRCodeComponent);
export default {
  components: {
    Multiselect,
    VerficationModal,
    MenuItems,
    // VueGoogleAutocomplete,
  },
  data() {
    return {
      value: "https://example.com",

      deliveryAddresses: [],
      meunItemsTempArray: [],
      deliveryAddress: null,
      minimunOrderAmout: null,
      deliveryCost: null,
      customers: [],
      riders: [],
      delivery_time: "",
      street_address: "",
      comment: "",
      type: "1",
      customerId: null,
      headerTitle: "Add Order",
      meunItems: [],
      totalMenuItemsPrice: 0.0,
      meunItemsIds: [],
      totalPrice: 0.0,
      changeClasss: false,
      showDeliveryTimeInput: false,
      showInputForDelivery: true,
      currentAddressname: "",
      restaurant_slug: "",
      email: "",
      temperature: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      voucher: null,
      user: null,
      users: [],
      imagesShow: false,
      showTable: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      orderResponceArray: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      riderId: null,
      showImagesViewTable: false,
      deliveryAddressView: false,
      discountList: [],
      orderDeliveryAddress: {},
      orderResponce: {},
      price: "",
      postcode: "",
      city: "",
      street: "",
      house_no: "",
      payment_type: 0,
      floor: "",
      user_address_id: "",
      show: true,
      apiUrl: "/user/orderPlace",
      orderStatus: null,
      isReadOnly: 0,
      totalPriceOfOrder: 0.0,
      latLngData: {},
      vouchersList: [],
      lat: "",
      lng: "",
      street_number: "",
      route: "",
      subTotalForPdf: 0.0,
      exist_in_area: false,
      additional_amount: 0,
      voucher_discount: 0,
      orderStatuses: [
        "pending",
        "accepted",
        "rejected",
        "cooking",
        "readytopickup",
        "dispatched",
        "ontheway",
        "completed",
      ],

      deliveryTime: null,
      payment_status: 0,
      calculationEnable: false,
      deliveryTimes: [
        { text: "Select Delivery Time", value: null },
        "30 Min",
        "60 Min",
        "90 Min",
        "120 Min",
      ],
    };
  },
  watch: {
    voucher: function() {
      this.voucherCalculation();
    },
  },
  mounted() {
    document.title = "Add Order";
    this.getAllRestaurant();
    this.getAllCustomers();
    this.getAllRider();
    if (this.$router.history.current.path == "/editorder") {
      this.headerTitle = "Edit Order";
      document.title = "Edit Order";

      this.editDataApppend();
    }
  },
  methods: {
    googlePlaceApi: function(addressData) {
      var places = addressData;
      this.lat = places.geometry.location.lat();
      this.lng = places.geometry.location.lng();
      for (var i = 0; i < places.address_components.length; i++) {
        for (var j = 0; j < places.address_components[i].types.length; j++) {
          if (places.address_components[i].types[j] == "postal_code") {
            this.postcode = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "locality") {
            this.city = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "street_number") {
            this.street_number = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "route") {
            this.route = places.address_components[i].long_name;
          }
        }
      }
      this.street = this.route + " " + this.street_number;

      let data = {
        postcode: this.postcode,
        restaurant_slug: this.restaurant_slug,
      };
      checkLatLng(data).then((responce) => {
        if (responce.code == 200) {
          let filtered = this.deliveryAddresses.find(
            (t) => t.postcode == this.deliveryAddress
          );
          if (filtered) {
            this.isReadOnly = 1;
            this.user_address_id = filtered.id;
            this.postcode = filtered.postcode;
            this.street = filtered.street;
            this.city = filtered.city;
            this.house_no = filtered.house_no;
            this.floor = filtered.floor;
            this.lat = filtered.lat;
            this.lng = filtered.lng;
          }
          this.exist_in_area = true;
          this.latLngData = responce.data[0].object_address;
          this.setOrderDeliverAddress();
          let deduct_cost = this.orderDeliveryAddress.delivery_address
            ? this.orderDeliveryAddress.delivery_cost
            : 0;
          this.totalPrice =
            parseFloat(this.totalPrice) +
            parseFloat(this.orderDeliveryAddress.delivery_cost) -
            parseFloat(deduct_cost);
        } else {
          this.postcode = "";
          this.city = "";
          this.street = "";
          this.$swal({
            text: responce.message,
            type: "warning",
            allowOutsideClick: false,
            closeOnClickOutside: false,
            allowEscapeKey: false,
          });
          this.exist_in_area = false;
        }
      });
    },
    generateSlip() {
      // var doc = new jsPDF("p", "pt", "letter"),
      //   source = $("#template_invoice")[0],
      //   margins = {
      //     top: 32,
      //     bottom: 10,
      //     left: 10,
      //     width: 200,
      //   };
      // doc.fromHTML(
      //   source, // HTML string or DOM elem ref.
      //   margins.left, // x coord
      //   margins.top,
      //   {
      //     // y coord
      //     width: margins.width, // max width of content on PDF
      //   },
      //   function() {
      //     // dispose: object with X, Y of the last line add to the PDF
      //     //          this allow the insertion of new lines after html
      //     doc.save("Test.pdf");
      //   },
      //   margins
      // );
      var doc = new jsPDF();
      var image = new Image();
      image.src = "./images/pdflogo.png";
      doc.addImage(image, "PNG", 95, 10, 20, 20);

      // x-coordinate, y-coordinate, width, height
      doc.setFontType("bold");
      doc.setFontSize(11);
      doc.text(88, 40, "Restaurant Details");
      doc.setFontType("normal");
      doc.setFontSize(11);
      doc.text(88, 47, this.orderResponce.restaurant.name);
      doc.text(88, 54, this.orderResponce.restaurant.phone_number);
      doc.text(82, 63, this.orderResponce.date);
      doc.text(112, 63, this.orderResponce.time);
      doc.text(82, 70, "Order Id");
      doc.text(112, 70, this.orderResponce.unique_code.toString());
      doc.setFontType("bold");
      doc.setFontSize(11);
      doc.text(88, 77, "Customer Details");
      doc.setFontType("normal");
      doc.setFontSize(11);
      if (this.orderResponce.ordertype == "Delivery") {
        doc.text(100, 83, this.orderResponce.customerDetail.username);
        doc.text(82, 89, this.orderResponce.customerDetail.email);
        doc.text(
          82,
          95,
          this.bindAddress(this.orderResponce.orderDeliveryAddress)
        );
        doc.text(82, 101, this.orderResponce.orderDeliveryAddress.floor);
        doc.text(
          82,
          107,
          this.bindAddress2(this.orderResponce.orderDeliveryAddress)
        );
        doc.text(94, 113, this.orderResponce.customerDetail.phone_number);
      } else {
        doc.text(100, 83, this.orderResponce.customerDetail.username);
        doc.text(82, 91, this.orderResponce.customerDetail.email);
        doc.text(90, 100, this.orderResponce.customerDetail.phone_number);
      }
      doc.text(83, 119, "*****************************");
      doc.setFontType("bold");
      doc.text(97, 122, "Important");
      doc.setFontType("normal");
      doc.text(90, 126, "Payment is " + this.orderResponce.paymentStatusText);
      doc.text(83, 131, "*****************************");
      doc.setFontType("bold");
      doc.text(101, 135, this.orderResponce.paymentTypeText);
      doc.setFontType("normal");
      doc.text(
        85,
        140,
        "Estimated Delivery Time: " + this.orderResponce.delivery_time
      );
      doc.setFontType("bold");
      doc.text(10, 150, "Order Details");
      autoTable(doc, {
        margin: { top: 160, left: 10 },
        html: "#bootstrap_table",
      });
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text(10, finalY + 6, "Bill Detail");

      doc.setFontType("normal");
      doc.text(10, finalY + 12, "Total Amount");
      doc.text(45, finalY + 12, this.orderResponce.totalAmountPaid + "€");
      doc.text(10, finalY + 18, "Delivery Charge");
      doc.text(
        45,
        finalY + 18,
        this.orderResponce.orderDeliveryAddress.delivery_cost
          ? this.orderResponce.orderDeliveryAddress.delivery_cost.toFixed(2)
          : "0.00" + "€"
      );
      doc.text(10, finalY + 27, "Paid Amount");
      doc.text(
        45,
        finalY + 27,
        (this.orderResponce.ordertype == "Delivery"
          ? this.orderResponce.totalAmountPaid +
            this.orderResponce.orderDeliveryAddress.delivery_cost
          : this.orderResponce.totalAmountPaid
        ).toFixed(2) + "€"
      );
      let base64Image = $("#qr_code img").attr("src");
      doc.addImage(base64Image, "png", 95, finalY + 35, 20, 20);
      doc.save("Report.pdf");
    },

    paidAmount() {
      return (
        this.totalPriceOfOrder +
        this.orderResponce.additional_amount -
        this.orderResponce.voucher_discount
      ).toFixed(2);
    },
    bindAddressForSelect(object) {
      let house_no = object.house_no ? object.house_no + ", " : "";
      let street = object.street ? object.street + ", " : "";
      let floor = object.floor ? object.floor + ", " : "";
      let postcode = object.postcode ? object.postcode + ", " : "";
      let city = object.city ? object.city : "";
      let completeAddress = street + house_no + floor + postcode + city;
      return completeAddress;
    },
    bindAddress(object) {
      let house_no = object.house_no ? object.house_no + " " : "";
      let street = object.street ? object.street + ", " : "";
      let completeAddress = street + house_no;
      return completeAddress;
    },

    bindAddress2(object) {
      let postcode = object.postcode ? object.postcode + ", " : "";
      let city = object.city ? object.city : "";
      let completeAddress = postcode + city;
      return completeAddress;
    },

    onlyForCurrency($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.temperature.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.temperature != null &&
        this.temperature.indexOf(".") > -1 &&
        this.temperature.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    onSelectAddress: function onSelectAddress() {
      if (this.restaurant_slug == "") {
        this.alert = true;
        this.alertText = "Please Select Restaurant First";
        return false;
      } else {
        this.alert = false;
      }
      if (this.deliveryAddress != null) {
        if (this.deliveryAddress == "new") {
          this.isReadOnly = 0;
          this.emptyFiled();
        } else {
          let data = {
            postcode: this.deliveryAddress.postcode,
            restaurant_slug: this.restaurant_slug,
          };
          checkLatLng(data).then((responce) => {
            if (responce.code == 200) {
              let filtered = this.deliveryAddresses.find(
                (t) => t.id == this.deliveryAddress.id
              );
              if (filtered) {
                this.isReadOnly = 1;
                this.user_address_id = filtered.id;
                this.postcode = filtered.postcode;
                this.street = filtered.street;
                this.city = filtered.city;
                this.house_no = filtered.house_no;
                this.floor = filtered.floor;
                this.lat = filtered.lat;
                this.lng = filtered.lng;
              }
              this.exist_in_area = true;
              this.latLngData = responce.data[0].object_address;
              this.setOrderDeliverAddress();
              let deduct_cost = this.orderDeliveryAddress.delivery_address
                ? this.orderDeliveryAddress.delivery_cost
                : 0;
              this.totalPrice =
                parseFloat(this.totalPrice) +
                parseFloat(this.orderDeliveryAddress.delivery_cost) -
                parseFloat(deduct_cost);
            } else {
              this.$swal({
                text: responce.message,
                type: "warning",
                allowOutsideClick: false,
                closeOnClickOutside: false,
                allowEscapeKey: false,
              });
              this.exist_in_area = false;
            }
          });
        }
      }
    },
    onChangeType() {
      if (this.type == "0") {
        this.showInputForDelivery = false;
      } else {
        this.showInputForDelivery = true;
      }
    },
    onChangeOrderStatus() {
      if (this.orderStatus == "completed") {
        this.payment_status = 1;
      }
      if (this.orderStatus == "accepted") {
        this.showDeliveryTimeInput = true;
      } else {
        this.showDeliveryTimeInput = false;
      }
    },
    getUserVoucher(userId) {
      const data = {
        type: "device",
        user_id: userId,
        tab: "issued",
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user_voucher/list", data, {
            headers,
          })
          .then((response) => {
            this.vouchersList = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getRestaurantMenus(restaurantId) {
      const data = {
        restaurant_id: restaurantId,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/customer/restaurant-detail", data, {
            headers,
          })
          .then((response) => {
            this.meunItems = response.data.data.meunItems;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    emptyFiled() {
      this.postcode = "";
      this.user_address_id = "";
      this.city = "";
      this.floor = "";
      this.street = "";
      this.house_no = "";
    },
    onSelectCustomer(object) {
      this.deliveryAddress = null;
      this.deliveryAddresses = object.address;
      this.getUserVoucher(object.id);
    },
    onSelectRider(object) {
      this.temperature = object.temperature
        ? object.temperature.temperature
        : "";
      this.riderId = object.id;
    },
    onSelect(option) {
      this.restaurant_slug = option.slug;
      this.totalPrice = 0.0;
      this.meunItemsIds = [];
      this.meunItemsTempArray = [];
      this.deliveryAddress = null;
      this.meunItems = option.meunItems;
      // this.deliveryAddresses = option.addresses;
      this.delivery_time = option.average_delivery_time;
    },

    onSelectDeliveryAddress(option) {
      this.calculationEnable = true;
      this.minimunOrderAmout = option.minimum_order_amount;
      this.deliveryCost = option.delivery_cost;
      const totalMenuItemsPrice = this.meunItemsTempArray.reduce(
        (sum, equity) => {
          return sum + equity.total_price;
        },
        0
      );
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(totalMenuItemsPrice) + parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },
    onDeliveryAddressRemove(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(this.totalPrice) - parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },

    editDataApppend() {
      this.editorder = JSON.parse(localStorage.getItem("editorder"));
      this.isReadOnly = 1;
      this.payment_status = this.editorder.payment_status;
      this.voucher_discount = this.editorder.voucher_discount;
      this.additional_amount = this.editorder.additional_amount;
      this.payment_type = this.editorder.payment_type;
      this.comment = this.editorder.comment;
      this.discountList = this.editorder.discounteditems;

      this.exist_in_area = true;
      this.id = this.editorder.id;
      this.meunItemsTempArray = this.editorder.customerOrdersDetail;
      this.riderId = this.editorder.rider;
      if (this.editorder.rider) {
        if (this.editorder.rider.temperature) {
          this.temperature = this.editorder.rider.temperature.temperature;
        }
      }
      this.selected = this.editorder.restaurant;
      this.voucher = this.editorder.voucher;
      if (this.selected) {
        this.restaurant_slug = this.selected.slug;
      }
      this.customerId = this.editorder.customerDetail;
      this.getUserVoucher(this.editorder.customerDetail.id);
      this.getRestaurantMenus(this.editorder.restaurant.id);
      this.totalPrice = this.editorder.totalprice.toFixed(2);
      this.orderStatus = this.editorder.order_status;
      if (this.orderStatus == "accepted") {
        this.showDeliveryTimeInput = true;
      } else {
        this.showDeliveryTimeInput = false;
      }
      this.street_address = this.editorder.street_address;
      this.deliveryAddresses = this.editorder.customerDetail.addresses;
      let deliveryAddress = this.editorder.orderDeliveryAddress;
      this.latLngData = deliveryAddress;
      this.orderDeliveryAddress = this.editorder.orderDeliveryAddress;

      if (deliveryAddress) {
        this.user_address_id = deliveryAddress.id;
        this.city = deliveryAddress.city;
        this.house_no = deliveryAddress.house_no;
        this.postcode = deliveryAddress.postcode;
        this.floor = deliveryAddress.floor;
        this.street = deliveryAddress.street;
        this.lat = deliveryAddress.lat;
        this.lng = deliveryAddress.lng;
        // this.setOrderDeliverAddress(deliveryAddress);
      }

      this.deliveryTime = this.editorder.delivery_time;
      if (this.editorder.ordertype == "Delivery") {
        this.type = "1";
        this.showInputForDelivery = true;
      } else {
        this.type = "0";
        this.showInputForDelivery = false;
      }

      this.id = this.editorder.id;
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    appendRow() {
      this.deliveryAddressArray.push(this.deliveryAddresstempArray[0]);
      this.$refs.autocomplete.clear();
      this.toasterMessage();
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
    },

    settimeObject(dateFormat) {
      let date = dateFormat;
      let timeobject = {
        h: date.getHours(),
        m: date.getMinutes(),
      };
      return timeobject;
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteMeunItem(object) {
      let index = this.meunItemsTempArray.findIndex(
        (x) => x.menu_id === object.item.menu_id
      );
      this.meunItemsTempArray.splice(index, 1);
      let grandTotal =
        parseFloat(this.totalPrice) - parseFloat(object.item.total_price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
      this.voucherCalculation();
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    customLabel({ name, current_address }) {
      return `${name}—(${current_address})`;
    },
    VoucherLabel({ unique_code, voucher }) {
      return `${unique_code}—(${voucher.type})`;
    },

    totalPriceIncrement(price) {
      this.calculationEnable = true;
      let grandTotal = parseFloat(this.totalPrice) + parseFloat(price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
      this.voucherCalculation();
    },

    removeDeliveryCost() {
      var totalAmount = 0;
      if (this.calculationEnable) {
        totalAmount =
          parseFloat(this.totalPrice) -
          parseFloat(
            this.orderDeliveryAddress.delivery_cost
              ? this.orderDeliveryAddress.delivery_cost
              : 0
          );
      } else {
        totalAmount =
          parseFloat(this.totalPrice) - parseFloat(this.additional_amount);
      }
      return totalAmount;
    },

    customLabelForCustomer({ username, email }) {
      return `${username}—(${email})`;
    },
    customLabelForRider({ f_name, l_name, email }) {
      return `${f_name ? f_name : ""} ${l_name ? l_name : ""} (${email})`;
    },
    customLabelDeliveryAddress({ street, city, state }) {
      return `(${street} ${city} ${state})`;
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    voucherCalculation() {
      if (this.voucher) {
        this.voucher_discount = this.voucher.voucher_discount;
      }
    },
    calculation() {
      this.totalPriceOfOrder = this.orderResponceArray.reduce(
        (acc, item) => acc + item.total_price,
        0.0
      );
      this.subTotalForPdf =
        parseFloat(this.totalPriceOfOrder) +
        parseFloat(
          this.orderResponce.ordertype == "Delivery"
            ? this.orderResponce.orderDeliveryAddress.delivery_cost
            : 0
        );
    },
    onSubmit(evt) {
      try {
        if (this.customerId == null) {
          this.validationMessage("Please select customer");
          evt.preventDefault();
          return false;
        }

        if (this.selected == null) {
          this.validationMessage("Please select restaurant");
          evt.preventDefault();
          return false;
        }
        if (!this.id) {
        if (!validTimeForOrder(this.selected, this.settimeObject(new Date()))) {
          this.$swal({
            html:
              "Restaurant is closed at the selected time. order place time will be <strong>" +
              this.selected.opening_time +
              " - " +
              this.selected.closing_time +
              "</strong>",
            allowOutsideClick: false,
            closeOnClickOutside: false,
            allowEscapeKey: false,
          });
          evt.preventDefault();
          return false;
        }
      }

        if (this.id) {
          if (
            this.meunItemsTempArray.length == 0 &&
            this.discountList.length == 0
          ) {
            this.validationMessage("Please select item");
            evt.preventDefault();
            return false;
          }
        } else {
          if (this.meunItemsTempArray.length == 0) {
            this.validationMessage("Please select item");
            evt.preventDefault();
            return false;
          }
        }

        if (this.type == "1") {
          if (this.orderDeliveryAddress == null) {
            this.validationMessage("Delivery Address Is Required");
            evt.preventDefault();
            return false;
          }
          if (this.house_no == "") {
            this.validationMessage("House No Is Required");
            evt.preventDefault();
            return false;
          }
          if (this.street == "") {
            this.validationMessage("Street  Is Required");
            evt.preventDefault();
            return false;
          }

          if (!this.exist_in_area) {
            this.validationMessage(
              "This restaurant doesn’t provide delivery services in your selected area"
            );
            evt.preventDefault();
            return false;
          }
          this.setOrderDeliverAddress();
        }
        if (this.orderStatus == "dispatched") {
          if (this.riderId == "") {
            this.validationMessage("Please Select Rider");
            evt.preventDefault();
            return false;
          }
        }

        let totalAmount = 0;
        if (this.calculationEnable) {
          totalAmount =
            parseFloat(this.totalPrice) -
            parseFloat(
              this.orderDeliveryAddress.delivery_cost
                ? this.orderDeliveryAddress.delivery_cost
                : 0
            );
        } else {
          totalAmount =
            parseFloat(this.totalPrice) - parseFloat(this.additional_amount);
        }
        if (this.voucher) {
          this.voucher_discount = this.voucher.voucher_discount;
        }

        const formData = new FormData();
        evt.preventDefault();
        formData.append("id", this.id);
        formData.append("customer_id", this.customerId.id);
        formData.append("rider_id", this.riderId ? this.riderId.id : "");
        formData.append("temperature", this.temperature);
        formData.append("restaurant_id", this.selected.id);
        formData.append("comment", this.comment);
        formData.append("totalprice", totalAmount);
        formData.append("voucher_discount", this.voucher_discount);
        formData.append("order_type", this.type);
        formData.append(
          "voucher_id",
          this.voucher ? this.voucher.unique_code : ""
        );
        formData.append("order_status", this.orderStatus);
        formData.append("street_address", this.street_address);
        formData.append("payment_type", this.payment_type);
        formData.append(
          "delivery_time",
          this.deliveryTime ? this.deliveryTime : ""
        );
        formData.append("payment_status", this.payment_status);
        formData.append("menuitems", JSON.stringify(this.meunItemsTempArray));
        formData.append(
          "deliveryAddress",
          JSON.stringify(this.orderDeliveryAddress)
        );
        if (this.calculationEnable) {
          let minimum_order_amount = this.orderDeliveryAddress
            .minimum_order_amount;
          let additional_amount = minimum_order_amount - totalAmount;
          if (this.totalPrice < minimum_order_amount && this.type == 1) {
            this.$swal({
              text:
                "Your order amount " +
                totalAmount.toFixed(2) +
                "€ is less than the minimum order amount " +
                minimum_order_amount.toFixed(2) +
                "€.  Do you want to place an order with minimum amount " +
                minimum_order_amount.toFixed(2) +
                "€. You have to pay " +
                additional_amount.toFixed(2) +
                "€ extra other than delivery charges",
              allowOutsideClick: false,
              showCancelButton: true,
              closeOnClickOutside: false,
              allowEscapeKey: false,
              cancelButtonText: "No",
              confirmButtonText: "Yes",
            }).then((result) => {
              if (!result.value) {
                evt.preventDefault();
                return false;
              } else {
                this.addLoaderClasses("save");

                const headers = {
                  Authorization: "Bearer " + localStorage.getItem("id_token"),
                };
                axios
                  .post(this.$path + this.apiUrl, formData, {
                    headers,
                  })
                  .then((response) => {
                    if (response.data.code == 200) {
                      if (this.orderStatus == "dispatched") {
                        this.orderResponce = response.data.data;
                        this.orderResponceArray = this.orderResponce.customerOrdersDetail;
                        this.calculation();
                        setTimeout(() => this.generateSlip(), 100);
                      }
                      let message = "";
                      if (this.id != "") {
                        message = "Record has been updated successfully";
                      } else {
                        message = "Record has been added successfully";
                      }
                      localStorage.setItem(
                        "alertmessage",
                        JSON.stringify(message)
                      );
                      setTimeout(
                        () => this.$router.push({ name: "orders" }),
                        100
                      );
                    }
                    if (response.data.code == 422) {
                      this.alert = true;
                      this.alertText = response.data.message;
                      this.removeLoaderClasses("save");
                    }

                    if (response.data.code == 501) {
                      this.$router.push({ name: "login" });
                    }
                  })
                  .catch(({ response }) => {
                    if (response) {
                      alert("Technical Issue");
                    }
                  });
              }
            });
            evt.preventDefault();
            return false;
          }
        }
        this.addLoaderClasses("save");

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              if (this.orderStatus == "dispatched") {
                this.orderResponce = response.data.data;

                this.orderResponceArray = this.orderResponce.customerOrdersDetail;

                this.calculation();
                setTimeout(() => this.generateSlip(), 100);
              }

              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              setTimeout(() => this.$router.push({ name: "orders" }), 100);
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    checkLatLngExist() {
      if (this.restaurant_slug == "") {
        this.alert = true;
        this.alertText = "Please Select Restaurant First";
        return false;
      } else {
        this.alert = false;
      }
      let data = {
        postcode: this.postcode,
        restaurant_slug: this.restaurant_slug,
      };
      if (this.postcode.length > 4) {
        checkLatLng(data).then((responce) => {
          if (responce.code == 200) {
            this.exist_in_area = true;
            this.latLngData = responce.data[0].object_address;
            this.setOrderDeliverAddress();

            let deduct_cost = this.orderDeliveryAddress.delivery_address
              ? this.orderDeliveryAddress.delivery_cost
              : 0;
            this.totalPrice =
              parseFloat(this.totalPrice) +
              parseFloat(this.orderDeliveryAddress.delivery_cost) -
              parseFloat(deduct_cost);
          } else {
            this.$swal({
              text: responce.message,
              type: "warning",
              allowOutsideClick: false,
              closeOnClickOutside: false,
              allowEscapeKey: false,
            });
            this.exist_in_area = false;
          }
        });
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    setOrderDeliverAddress() {
      this.orderDeliveryAddress = {
        id: this.user_address_id,
        postcode: this.postcode,
        city: this.city,
        house_no: this.house_no,
        street: this.street,
        floor: this.floor,
        lat: this.lat,
        lng: this.lng,
        delivery_cost: this.latLngData.delivery_cost,
        minimum_order_amount: this.latLngData.minimum_order_amount,
      };
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomers() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/user-dropdown", "", {
            headers,
          })
          .then((response) => {
            this.customers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllRider() {
      const data = {
        type: "admin",
        pagination: false,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/rider/rider-list", data, {
            headers,
          })
          .then((response) => {
            this.riders = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
