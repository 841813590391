export function bar(id) {
  return id;
}

export function appendRow(array) {
  this.array.push(this.array.length + 1);
  return array;
}

export function deleteRow(value) {
  let index = this.columns.findIndex((x) => x === value);
  if (index !== -1) {
    this.columns.splice(index, 1);
    this.selectedAddress.splice(index, 1);
  }
}

function isvalidTimeForOrder(object, h1, m1, h2, m2) {
  var h = object.h;
  var m = object.m;
  return (h1 < h || (h1 == h && m1 <= m)) && (h < h2 || (h == h2 && m <= m2));
}

export function validTimeForOrder(restaurantObject, timeObject) {
  let openingTime = restaurantObject.opening_time;
  let closingTime = restaurantObject.closing_time;
  let opeingHour = openingTime.split(":")[0];
  let opeingminuts = openingTime.split(":")[1];
  let closingHour = closingTime.split(":")[0];
  let closingminuts = closingTime.split(":")[1];
  let validTimeForOrder = isvalidTimeForOrder(
    timeObject,
    opeingHour,
    opeingminuts,
    closingHour,
    closingminuts
  );
  if (!validTimeForOrder) {
    return false;
  }
  return true;
}
